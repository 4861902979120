<template>
  <div>
    <v-menu
      open-delay="150"
      close-delay="150"
      offset-y
      max-width="292px"
      min-width="292px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div>
          <v-btn
            :large="!$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
            v-on="on"
            v-bind="attrs"
          >
            <v-icon
              v-if="icon"
              size="20"
              :class="!$vuetify.breakpoint.smAndDown ? 'mr-2' : ''"
            >
              {{ icon }}
            </v-icon>
            <template v-if="!$vuetify.breakpoint.smAndDown">
              {{ label }}
              <v-icon size="24" class="ml-2 me-0">$chevrondown</v-icon>
            </template>
            <template v-else-if="$vuetify.breakpoint.smAndDown && mobileIcon">
              <v-icon size="20" class="me-0" color="white"> ${{ mobileIcon }}</v-icon>
            </template>
          </v-btn>
        </div>
      </template>

      <v-list class="elevation-0">
        <v-list-item
          class="mx-2"
          v-for="(item, index) in actions"
          :key="item.title + index"
          @click="
            item.action && typeof item.action == 'function'
              ? item.action()
              : undefined
          "
          :class="
            item.color
              ? item.color === 'sand'
                ? 'sand-on-hover'
                : `${item.color}-on-hover`
              : undefined
          "
          :to="item.link ? item.link : undefined"
        >
          <v-list-item-icon style="min-width: unset !important;">
            <v-icon
              :size="item.iconSize ? item.iconSize : '32'"
              style="min-width: none;"
              :class="
                item.color
                  ? item.color === 'sand'
                    ? 'sand-on-hover'
                    : `${item.color}-on-hover`
                  : undefined
              "
            >
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "QuickActionsButton",
  props: {
    actions: Array,
    hideCustomizeOption: Boolean,
    mobileIcon: String,
    icon: {
      type: [String, Boolean],
      default: "$plusIcon",
    },
    label: {
      type: String,
      default: "Quick Add...",
    },
  },
}
</script>