var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"open-delay":"150","close-delay":"150","offset-y":"","max-width":"292px","min-width":"292px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"p-2 add-new-button page-title__add-button",attrs:{"large":!_vm.$vuetify.breakpoint.smAndDown,"elevation":"0","color":"primary"}},'v-btn',attrs,false),on),[(_vm.icon)?_c('v-icon',{class:!_vm.$vuetify.breakpoint.smAndDown ? 'mr-2' : '',attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown)?[_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-icon',{staticClass:"ml-2 me-0",attrs:{"size":"24"}},[_vm._v("$chevrondown")])]:(_vm.$vuetify.breakpoint.smAndDown && _vm.mobileIcon)?[_c('v-icon',{staticClass:"me-0",attrs:{"size":"20","color":"white"}},[_vm._v(" $"+_vm._s(_vm.mobileIcon))])]:_vm._e()],2)],1)]}}])},[_c('v-list',{staticClass:"elevation-0"},_vm._l((_vm.actions),function(item,index){return _c('v-list-item',{key:item.title + index,staticClass:"mx-2",class:item.color
            ? item.color === 'sand'
              ? 'sand-on-hover'
              : `${item.color}-on-hover`
            : undefined,attrs:{"to":item.link ? item.link : undefined},on:{"click":function($event){item.action && typeof item.action == 'function'
            ? item.action()
            : undefined}}},[_c('v-list-item-icon',{staticStyle:{"min-width":"unset !important"}},[_c('v-icon',{class:item.color
                ? item.color === 'sand'
                  ? 'sand-on-hover'
                  : `${item.color}-on-hover`
                : undefined,staticStyle:{"min-width":"none"},attrs:{"size":item.iconSize ? item.iconSize : '32'}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }